

import schttp from 'public/src/services/schttp'
import { getStoreCodeByUrlString, 
} from 'public/src/pages/store_pages/js/utils.js'
import { markPoint } from 'public/src/services/mark/index.js'
import { getDefaultKidParamsByAb } from 'public/src/pages/components/FilterBar/utils/kids.js'



class StorePageDataManager {
  constructor() {
    // 单例模式
    if (StorePageDataManager.instance) {
      return StorePageDataManager.instance
    }
    this.init()
    StorePageDataManager.instance = this

  }
  init() {
    this.fullPath = ''
    this.schttpAbortCon = null
    this.pageDataRequest = null
    this.pageSsrDataResolved = false
  }


  async prerenderRequest(route) {
    return this.request(route)
  }
  request(route) {
    if (typeof window === 'undefined') return null
    if (!route) return null
    if (route.fullPath !== this.fullPath) {
      this.fullPath = route.fullPath
      markPoint({ eventName: 'waitPageData', measureFrom: 'toNextPageClick' })
      this.pageDataRequest = this.fetchPageData(route)
    }
    return this.pageDataRequest
  }
  async fetchPageData(route) {
    if (this.schttpAbortCon) {
      this.schttpAbortCon.abort()
    }
    this.schttpAbortCon = new SchttpAbortCon()
    const pathname = this.getPathnameFromFullPath(route.fullPath)
    const params = {
      store_code: getStoreCodeByUrlString(route.fullPath),
      ...route.query,
      ...getDefaultKidParamsByAb(pathname), // 用于童装
      isInfoData: '1',
      i18n: true,
    }
    try {
      const resData = await schttp({
        url: '/api/store/page/get',
        params,
        signal: this.schttpAbortCon.signal,
        schttp: { getResponse: true },
      })

      if(!resData?.data){
        throw resData
      }
      const { code, info } = resData.data
      if(code !== '0'){
        throw resData.data
      }
      markPoint({ eventName: 'pageDataReady',  measureFrom: 'waitPageData' })
      return info
    } catch (error) {
      this.fullPath = ''
      // 返回错误信息--SILogger 上报到skynet
      return {
        isError: true,
        msg: `code:${error?.code} | message:${error?.msg || error?.message || '' }`,
        traceId: error?.traceId,
        name: error?.name,
      }
    }
  }

  reset() {
    this.init()
  }

  getPathnameFromFullPath(fullPath){
    if(!fullPath) return ''
    const index = fullPath.indexOf('?')
    if(index >= 0){
      return fullPath.slice(0, index)
    }
    return fullPath
  }
}

export default new StorePageDataManager()

